<template>
  <InstantSearch
    :enable-routing="false"
    :contexts="contexts.split(',')"
    :filters="getFilters"
    :optional-filters="optionalFilters"
    :analytics-tags="contexts.split(',')"
    :hits-per-page="200"
  >
    <Hits v-slot="{ hits }">
      <div class="Grid">
        <ProductCard v-for="hit in hits" :key="hit.objectID" class="Grid__item" :product="hit" />
      </div>
    </Hits>
  </InstantSearch>
</template>

<script>
import { gsap } from 'gsap/all';

import { AisStateResults, AisSortBy } from 'vue-instantsearch';
import { InstantSearch, Hits } from '@components/Search';
import ProductCard from '@components/Cards/ProductCard';

import { routing, searchClient } from '@algolia';

import tracking from '@tracking';
import settings from '@settings';

export default {
  name: 'ProductsScene',

  components: {
    InstantSearch,
    Hits,
    ProductCard,
    AisSortBy,
  },

  props: {
    optionalFilters: {
      type: String,
      default: '',
    },
    filters: {
      type: String,
      default: '',
    },
    contexts: {
      type: String,
      default: '',
    },
    sortFilters: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {};
  },

  computed: {
    getFilters() {
      return this.filters;
    },
  },

  created() {
    // Listen for this component role event
  },

  methods: {
    scrollUp() {
      gsap.to(window, { duration: 0.8, delay: 0.2, scrollTo: { y: this.$el, offsetY: 80 } });
    },
  },
};
</script>

<style lang="scss" scoped>
.Grid {
  margin-bottom: 40px;
}

.FiltersOptions {
  display: flex;
  margin-bottom: 40px;

  @media only screen and (min-width: $tablet) {
    margin-bottom: 20px;
  }

  .ValuesAsText {
    span {
      font-weight: bold;
      font-size: 18px;
    }
  }
}
</style>
<style lang="scss">
.FiltersOptions {
  .ValuesAsText {
    span {
      font-weight: bold;
      font-size: 18px;
      @include maxBreakpoint(600) {
        font-size: 14px;
      }
    }
  }
}
</style>
